<template>
  <b-container class="page-body mx-lg">
    <h1 class="title">{{ translations.tcCalendar }}</h1>
    <div class="b-container">
      <div class="row">
        <div class="picker">
          <b-form-select v-model="selected" :options="options" @change="calendarChange($event)"></b-form-select>
        </div>
        <b-button class="autopopulate" variant="primary" @click="autoPopulate()" v-if="canAutoPopulate()">{{
        translations.tcAutoPopulate
        }}</b-button>
      </div>
      <div class="row btn-group">
        <div class="addbtn">
          <b-button class="add" variant="primary" @click="addCalendarItem()">{{
          translations.tcAddCalendarItem
          }}</b-button>
        </div>
        <div class="uploadbtn">
          <b-button class="upload" @click="handleCSVUpload()">{{ translations.tcUploadCSV }}</b-button>
          <a class="btn btn-dark flex-0 mr-2" @click.prevent="downloadReport(csvUrl, 'CalendarItems', 'csv')">{{
          translations.tcDownloadCSV
          }}</a>
        </div>
      </div>
    </div>
    <div class="calendar-area">
      <b-row class="text-center text-color-black">
        <b-col cols="2">
          <b-button class="btn-no-content-smaller">
            <b-icon icon="chevron-left" aria-hidden="true"></b-icon>
          </b-button>
        </b-col>
        <b-col cols="2">
          <h5>{{ currentMonth }}</h5>
        </b-col>
        <b-col cols="2">
          <b-button class="btn-no-content-smaller">
            <b-icon icon="chevron-right" aria-hidden="true"></b-icon>
          </b-button>
        </b-col>
      </b-row>
      <b-row class="calendar">
        <b-col>
          <full-calendar v-if="translations.common" :config="config" :events.sync="events"
            @event-selected="selectedEvent" />
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import dateData from '@/json/date.json'
import { FullCalendar } from 'vue-full-calendar'
import 'fullcalendar/dist/fullcalendar.css'
import ReportHeader from '@/components/ReportHeader.vue'
import { translationMixin } from '@/mixins/translationMixin'
import { reportDownloadMixin } from '@/mixins/reportDownloadMixin'

import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

export default {
  name: 'prayer-manage-calendar',
  mixins: [translationMixin, reportDownloadMixin],
  data() {
    return {
      currentMonth: '',
      translations: {},
      zone: '',
      csvUrl: '',
      showingDate: null,
      events: [],
      zones: [],
      hiddenItems: {
        display: false,
      },
      config: {
        defaultView: 'month',
        displayEventTime: false,
        display: 'block',
        header: false,
        eventBackgroundColor: '#003946',
        eventTextColor: '#F0F0F0',
        locale: 'en',
        weekday: 'short',
        eventRender: (event, element) => { },
      },
      selected: null,
      options: [],
    }
  },
  methods: {
    ...mapActions({
      autoGenerate: 'prayerCenter/autoGenerate',
      downloadPrayerCalendarUrl: 'prayerCenter/downloadPrayerCalendarUrl',
      getCampPrayerListing: 'prayerCenter/getCampPrayerListing',
      getStatePrayerListing: 'prayerCenter/getStatePrayerListing',
      getInternationalPrayerListing: 'prayerCenter/getInternationalPrayerListing',
      getMonthPrayerRequest: 'prayerCenter/getMonthPrayerRequest',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedPrayerCalendarKey: 'prayerCenter/setSelectedPrayerCalendarKey',
      setSelectedPrayerRequest: 'prayerCenter/setSelectedPrayerRequest',
    }),
    addPresenter() {
      this.$router.push('/')
    },
    async selectedEvent(event) {
      await this.setSelectedPrayerRequest({
        details: event.data,
        orgKey: this.orgKey,
        type: 'edit',
      })
      this.$router.push({ name: 'prayer-details' })
    },
    async handleCSVUpload() {
      await this.setSelectedPrayerCalendarKey(this.calendarItem.key)
      this.$router.push({ name: 'prayer-calendar-upload' })
    },
    async calendarChange(evt) {
      await this.getMonthPrayerRequest({
        orgKey: this.orgKey,
        cprKey: evt,
      }).then(() => {
        this.extrapolateEvents()
      })
    },
    canAutoPopulate() {
      if (
        this.calendarItem.level == 'camp' &&
        (this.calendarItem.category.toLowerCase() == 'churches' ||
          this.calendarItem.category.toLowerCase() == 'members')
      ) {
        return true
      } else if (
        this.calendarItem.level == 'state' &&
        (this.calendarItem.category.toLowerCase() == 'widows' || this.calendarItem.category.toLowerCase() == 'officers')
      ) {
        return true
      }
      return false
    },
    async autoPopulate() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: this.translations.tcWarningRegeneratePrayer,
        confirmButtonText: this.translations.tcOk || 'Ok',
        cancelButtonText: this.translations.tcCancel || 'Cancel',
      }).then((result) => {
        if (result.value) {
          //autogenerate
          this.generate()
        }
      })
    },
    async generate() {
      this.setLoadingStatus(true)
      const response = await this.autoGenerate(this.calendarItem.key)
      if (response) {
        await this.pageLoad()
      } else {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorTryAgain,
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
      }
      this.setLoadingStatus(false)
    },
    async getCalendarPrayerRequests(data) {
      await this.getMonthPrayerRequest(data).then(() => {
        this.extrapolateEvents()
      })
    },
    async setOptions() {
      let listing = []
      let sel = this.campPrayerListing.calendars.filter((cpf) => cpf.key === this.calendarItem.key)
      if (!!sel && sel.length > 0) {
        listing = this.campPrayerListing.calendars
      } else {
        sel = this.statePrayerListing.calendars.filter((spf) => spf.key === this.calendarItem.key)
        if (!!sel && sel.length > 0) {
          listing = this.statePrayerListing.calendars
        } else {
          sel = this.internationalPrayerListing.calendars.filter((ipf) => ipf.key === this.calendarItem.key)
          if (!!sel && sel.length > 0) {
            listing = this.internationalPrayerListing.calendars
          }
        }
      }
      this.options = listing
        .filter((cgf) => cgf.type === 'Daily' && cgf.active)
        .map((cgm) => {
          return {
            value: cgm.key,
            text: cgm.name,
            active: cgm.active,
            category: cgm.category,
            key: cgm.key,
            name: cgm.name,
            type: cgm.type,
          }
        })
    },
    extrapolateEvents() {
      if (!!this.monthPrayerRequest && this.monthPrayerRequest.length > 0) {
        this.events = this.monthPrayerRequest.map((data) => {
          let startDate = new Date(this.showingDate.getFullYear(), this.showingDate.getMonth(), data.day, 1, 2, 3)
          let eventDetails = {
            title: data.title,
            start: startDate,
            data: data,
          }
          return { ...eventDetails }
        })
      }
    },
    async addCalendarItem() {
      await this.setSelectedPrayerRequest({
        orgKey: this.orgKey,
        cprKey: this.selected,
        type: 'add',
      })
      this.$router.push({ name: 'prayer-edit-calendar-item' })
    },
    async pageLoad() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          await this.getCalendarPrayerRequests({
            orgKey: this.orgKey,
            cprKey: this.calendarItem.key,
          }),
          await this.getCampPrayerListing(),
          await this.getStatePrayerListing(),
          await this.getInternationalPrayerListing(),
        ]).then(() => {
          this.setOptions()
          this.selected = this.calendarItem.key
        })
        this.csvUrl = await this.downloadPrayerCalendarUrl(this.calendarItem.key)
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    setCurrentMonth() {
      const monthIndex = this.showingDate.getMonth()
      const monthData = this.monthsJson[monthIndex]
      const currentMonthText = this.translations.common['months'][monthData.guidFull]
      this.currentMonth = currentMonthText ? currentMonthText : monthData.text
    },
  },
  async created() {
    this.monthsJson = dateData.months
    this.showingDate = new Date()
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('common.months'),
      await this.pageLoad(),
    ]).then((results) => {
      this.config.locale = this.prefCulture
      this.stripReadableText(results[1])
      const translatedText = {
        ...results[0],
        common: { ...this.translations.common },
      }
      this.$set(this.translations, 'components', translatedText)
      this.setCurrentMonth()
      this.options.unshift({ value: null, text: this.translations.tcSelect })
    })
  },
  computed: {
    ...mapGetters({
      calendarItem: 'prayerCenter/getCalendarItem',
      campKey: 'user/userCampKey',
      campPrayerListing: 'prayerCenter/campPrayerListing',
      dailyPrayers: 'prayerCenter/dailyData',
      internationalKey: 'user/internationalKey',
      internationalPrayerListing: 'prayerCenter/internationalPrayerListing',
      monthPrayerRequest: 'prayerCenter/monthPrayerRequest',
      prefCulture: 'user/userPreferredCulture',
      statePrayerListing: 'prayerCenter/statePrayerListing',
      stateKey: 'user/userStateKey',
      userIndividualKey: 'user/userId',
    }),
    showingMonth() {
      let monthName = dateData.months.find((mi) => parseInt(mi.value) === this.showingDate.getMonth() + 1).text
      return `${monthName}`
    },
    orgKey() {
      return (
        { camp: this.campKey, country: this.stateKey, state: this.stateKey, headquarters: this.internationalKey }[
        this.calendarItem.level
        ] || ''
      )
    },
  },
  components: {
    campSelector: CampSelect,
    FullCalendar,
    reportHeader: ReportHeader,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/views/ReportCenter.scss';

.text-center {
  justify-content: center;
}

.title {
  color: #000;
  font-size: 4em;
  text-align: left;
  font-weight: 100;
}

.row {
  margin: 0;
}

.test-row {
  margin-top: 4em;
  color: #000;
}

.expire-row {
  color: #000;

  h5 {
    margin-left: 2rem;
    color: #636363;
    font-weight: 800;
  }
}

.namediv {
  h5 {
    margin-top: 10px;
    margin-right: 10px;
    color: #636363;
    font-weight: 800;
  }
}

.picker {
  margin-right: 15px;

  select {
    min-width: 300px;
    height: 46px;
    color: #003946;
    font-weight: bold;
    border-radius: unset;
  }
}

.autopopulate {
  height: fit-content !important;
}

.btn-group {
  margin: 30px 0;
  width: 100%;
}

.uploadbtn {
  margin-left: 45%;

  .upload {
    margin-right: 20px;
  }

  .download {
    background: #707070;
  }
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #003946;
  border-color: #003946 !important;
}
</style>
